<template>
  <!-- Table Container Card -->
  <div class="table-resize">
    <b-table
        ref="refDocumentListTable"
        :items="documents"
        :small="false"
        :fields="tableColumns"
        primary-key="id"
        :hover="true"
        :head-variant="null"
        :sort-by.sync="sortBy"
        :busy="isBusy"
        show-empty
        :empty-text="$t('document.noDocumentFoundForCriteria')"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative shared-table">

      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>{{ $t('loading') }}</strong>
        </div>
      </template>
      <template #head(code)>
        <feather-icon icon="HashIcon"
                      class="mx-auto"/>
      </template>
      <template #head(title)>
        <span>{{ $t('table.title') }}</span>
      </template>
      <template #head(status)>
        <span>{{ $t('table.status') }}</span>
      </template>
      <template #head(approveRatio)>
        <span>{{ $t('table.approveRatio') }}</span>
      </template>
      <template #head(documentType)>
        <span>{{ $t('table.documentType') }}</span>
      </template>
      <template #head(updatedAt)>
        <span>{{ $t('table.updatedAt') }}</span>
      </template>

      <!-- Column: code -->
      <template #cell(code)="data">
        <b-link :to="{ name: 'editor', params: { id: data.item.id }}" class="font-weight-bold">
          #{{ data.value }}
        </b-link>
      </template>
      <template #cell(title)="data">
        <div>
          <div class="row">
            <b-link class="text-black" :to="{ name: 'editor', params: { id: data.item.id }}">
              <span class="font-weight-bolder text-black">{{ data.value }}</span>
            </b-link>
          </div>
          <div class="row">
            <span class="text-black-50 font-weight-bolder font-small-3">{{ data.item.workspace }}</span>
          </div>
        </div>
      </template>
      <template #cell(documentType)="data">
        <b-link :to="{ name: 'editor', params: { id: data.item.id }}"
                class="font-weight-bold">
          <b-badge pill
                   :variant="`light-${resolveDocumentTypeVariant(data.item.documentType.displayText)}`"
                   class="text-capitalize">
            {{ $t('documentTypes.'.concat(data.item.documentType.name)) }}
          </b-badge>
        </b-link>
      </template>
      <template #cell(status)="data">
        <b-link :to="{ name: 'editor', params: { id: data.item.id }}"
                class="font-weight-bold">
          <b-avatar :id="`document-row-${data.item.id}`"
                    size="32"
                    :variant="`light-${resolveDocumentStatusVariantAndIcon(data.item.lifecycleDetail.status.name).variant}`">
            <feather-icon :icon="resolveDocumentStatusVariantAndIcon(data.item.lifecycleDetail.status.name).icon"/>
          </b-avatar>
          <b-tooltip :target="`document-row-${data.item.id}`"
                     placement="top">
            <p class="mb-0">
              {{ $t('documentStates.'.concat(data.item.lifecycleDetail.status.name)) }}
            </p>
          </b-tooltip>
        </b-link>
      </template>
      <template #cell(approveRatio)="data">
        <b-link :to="{ name: 'editor', params: { id: data.item.id }}"
                class="font-weight-bold">
          <div class="d-flex align-items-center">
            <vue-apex-charts type="radialBar"
                             height="30"
                             width="30"
                             :options="options"
                             :series="[data.item.approveRatio]"/>
            <span class="font-weight-bold text-body-heading mr-1 ml-1">{{ data.item.approveRatio }}%</span>
          </div>
        </b-link>
      </template>
      <template #cell(updatedAt)="data">
        <b-link :to="{ name: 'editor', params: { id: data.item.id }}"
                class="font-weight-bold">
          <span class="text-nowrap">
            {{ getFormattedDate(data.item.updatedAt) }}
          </span>
        </b-link>
      </template>
    </b-table>

    <div class="mx-2 mb-2">
      <b-row>
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
          <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
          <span class="text-muted">
						{{
              $tc('itemCountOnTable', dataMeta.from, dataMeta.to, {
                from: dataMeta.from,
                to: dataMeta.to
              })
            }}
					</span>
        </b-col>
        <!-- Pagination -->
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination
              v-model="currentPage"
              :total-rows="totalDocuments"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18"/>
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18"/>
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {BAvatar, BBadge, BCol, BLink, BPagination, BRow, BTable, BTooltip, VBPopover} from "bootstrap-vue";
import DateUtil from '@/services/DateUtil'
import dateUtil from '@/services/DateUtil'
import Ripple from "vue-ripple-directive";
import VueApexCharts from 'vue-apexcharts'

export default {
  name: "SharedDocumentList",
  directives: {
    'b-popover': VBPopover,
    Ripple
  },
  components: {
    BRow,
    BCol,
    BTable,
    BAvatar,
    BLink,
    BBadge,
    BPagination,
    BTooltip,
    VueApexCharts
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      titleSearchQueryParam: "",
      codeSearchQueryParam: "",
      dateRangeQueryParam: [],
      dateRangeStartQueryParam: null,
      dateRangeEndQueryParam: null,
      documentTypeQueryParam: null,
      documentStatusQueryParam: null,
      labelSearchQueryParam: null,
      options: {
        grid: {
          show: false,
          padding: {
            left: -15,
            right: -15,
            top: -12,
            bottom: -15,
          },
        },
        colors: ['#A9A2F6'],
        plotOptions: {
          radialBar: {
            hollow: {
              size: '22%',
            },
            track: {
              background: '#e9ecef',
            },
            dataLabels: {
              showOn: 'always',
              name: {
                show: false,
              },
              value: {
                show: false,
              },
            },
          },
        },
        stroke: {
          lineCap: 'round',
        },
      },
    }
  },
  mounted() {
    this.loadDocumentTypes()
    this.loadDocumentStatuses()
  },
  props: {
    isBusy: Boolean,
    documents: Array,
    newDocumentUrl: String,
    totalDocuments: Number,
    tableColumns: Array,
    perPageOptions: Array,
    sortBy: String,
    isSortDirDesc: Boolean,
    fetch: Function,
    dataMeta: Object,
    resolveDocumentStatusVariantAndIcon: Function,
    resolveDocumentTypeVariant: Function,
  },
  computed: {
    getNewDocumentUrl() {
      return this.newDocumentUrl;
    },
    documentTypes() {
      return this.$store.getters['document/getDocumentTypes']
    },
    documentStatuses() {
      return this.$store.getters['document/getDocumentStatuses']
    },
  },
  methods: {
    openNewDocument() {
      this.$router.replace(this.newDocumentUrl)
      this.cleanStates()
    },
    cleanStates() {
      this.$store.dispatch('document/cleanVariables')
      this.$store.dispatch('document/refreshDocument')
    },
    getFormattedDate(date) {
      return DateUtil.getFormattedTime(date);
    },
    loadDocumentStatuses() {
      this.$store.dispatch('document/getDocumentStatuses')
    },
    loadDocumentTypes() {
      this.$store.dispatch('document/getDocumentTypes')
    },
    filterByDate(event) {
      this.$emit('toggle-filter', true)
      this.dateRangeStartQueryParam = event[0]
      this.dateRangeEndQueryParam = event[1]

      this.updateDocumentQuery()
    },
    updateDocumentQuery() {
      this.$emit('toggle-filter', true)
      const payload = {
        code: this.codeSearchQueryParam,
        documentTitle: this.titleSearchQueryParam,
        paging: {
          page: this.currentPage,
          size: this.perPage
        }
      }

      if (this.labelSearchQueryParam !== null && this.labelSearchQueryParam !== "") {
        payload.labels = [
          this.labelSearchQueryParam
        ]
      }

      if (this.documentStatusQueryParam !== null) {
        payload.documentStatuses = [
          this.documentStatusQueryParam.name
        ]
      }

      if (this.documentTypeQueryParam !== null) {
        payload.documentTypes = [
          this.documentTypeQueryParam.name
        ]
      }

      if (this.dateRangeStartQueryParam !== null || this.dateRangeEndQueryParam !== null) {
        payload.createdAt = {
          start: dateUtil.getFormattedDate2(this.dateRangeStartQueryParam),
          end: dateUtil.getFormattedDate2(this.dateRangeEndQueryParam)
        }
      }

      this.$store.dispatch('document/queryDocuments', payload)
    },
    cleanFilterParameters() {
      this.$emit('toggle-filter', false)
      this.currentPage = 1
      this.perPage = 10
      this.titleSearchQueryParam = ""
      this.codeSearchQueryParam = ""
      this.documentTypeQueryParam = null
      this.documentStatusQueryParam = null

      this.$store.dispatch('document/loadSharedDocuments')
    }
  }
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.table-resize {
	@media screen and (max-width: 900px) {
		overflow: auto;
		.shared-table {
			min-width: 1000px;
		}
	}
}

.shared-table {
	tbody tr td {
		span {
			@media screen and (max-width: 1550px) {
				font-size: 14px;
			}
		}
		.badge {
			font-size: 12px;
		}
	}
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>