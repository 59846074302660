<template>
    <div>
        <loading v-if="loading"></loading>
        <div v-else>
            <Empty :new-document-url="getNewDocumentUrl()" v-if="!isFilterEnabled && documentList.length === 0"/>

            <SharedDocumentList v-else
                          :new-document-url="getNewDocumentUrl()"
                          :is-busy="loading"
                          :per-page="perPage"
                          :per-page-options="perPageOptions"
                          :documents="documentList"
                          :total-documents="totalDocuments"
                          :fetch="fetchSharedDocuments"
                          :table-columns="tableColumns"
                          :data-meta="dataMeta"
                          :current-page="currentPage"
                          @toggle-filter="toggleFilter"
                          :resolve-document-type-variant="resolveDocumentTypeVariant"
                          :resolve-document-status-variant-and-icon="resolveDocumentStatusVariantAndIcon">
            </SharedDocumentList>
        </div>
    </div>
</template>

<script>
  import Empty from "@/views/Documents/Shared/Empty";
  import SharedDocumentList from "@/views/Documents/Shared/SharedDocumentList"
  import {mapState} from "vuex";
  import {avatarText} from "@core/utils/filter";
  import useSharedDocumentList from "@/views/Documents/Shared/useSharedDocumentList";

  import Loading from "@/views/components/Loading/Loading";

  export default {
    name: "List",
    components: {Loading, Empty, SharedDocumentList},
    computed: {
      ...mapState({
        documentList: state => state.document.sharedDocumentList,
        loading: state => state.document.listLoading
      }),
    },
    data() {
      return {
        isFilterEnabled: false
      }
    },
    setup() {
      const statusOptions = [
        'Downloaded',
        'Draft',
        'Paid',
        'Partial Payment',
        'Past Due',
      ]

      const {
        fetchSharedDocuments,
        getNewDocumentUrl,
        tableColumns,
        perPage,
        currentPage,
        totalDocuments,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refDocumentListTable,
        statusFilter,
        reFetchData,
        resolveDocumentStatusVariantAndIcon,
        resolveDocumentTypeVariant,
      } = useSharedDocumentList()

      return {
        fetchSharedDocuments,
        getNewDocumentUrl,
        tableColumns,
        perPage,
        currentPage,
        totalDocuments,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refDocumentListTable,
        statusFilter,
        reFetchData,
        statusOptions,
        avatarText,
        resolveDocumentStatusVariantAndIcon,
        resolveDocumentTypeVariant,
      }
    }, created() {
      this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
      this.$store.commit('appConfig/UPDATE_NAVBAR_CONFIG', {type: 'sticky'})
      this.$store.commit('appConfig/UPDATE_FOOTER_CONFIG', {type: 'static'})
      this.$store.commit('appConfig/UPDATE_CONTENT_WIDTH', 'boxed')
    },
    mounted() {
      this.fetchSharedDocuments()
    },
    methods: {
      toggleFilter(event) {
        this.isFilterEnabled = event
      }
    }
  }
</script>

<style scoped>

</style>